@import "bootstrap/scss/bootstrap";
@import '/src/scss/variables.scss';
@import '/src/scss/typography.scss';
@import '/src/scss/herobanner.scss';
@import '/src/scss/technologies.scss';
@import '/src/scss/intro.scss';
@import '/src/scss/portfolio.scss';
@import '/src/scss/testimonial.scss';
@import '/src/scss/footer.scss';

::-moz-selection {
    background: #f2c2c9;
    color: #a4003a;
    text-shadow: none;
}

::selection {
    background: #16a085;
    color: #a4003a;
    text-shadow: none;
}

hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #ccc;
    margin: 1em 0;
    padding: 0;
}

.centered {
    justify-content: center;
    align-items: center
}

.mt {
    margin-top: 80px;
}

#aboutwrap {
    margin-top: -70px;
    padding-top: 250px;
    text-align: center;
    background-attachment: relative;
    background-position: center center;
    min-height: 550px;
    width: 100%;

    -webkit-background-size: 100%;
    -moz-background-size: 100%;
    -o-background-size: 100%;
    background-size: 100%;

    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.gallery {
    margin-top: 30px;
}

.gallery img:hover {
    opacity: 0.4;
}

@media(max-width: 540px) {
    #name {
        font-size: 30pt !important;
    }
}


#signature p {
    margin-top: 20px;
}

.academy-badge img {
    max-width: 100%;
    width: 300px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.academy-badge {
    text-align: center;
}